@import "main.css";
/*----------------------------------------  Supprimer bandeau pages secondaires*/
.websitePageTitle-div {
display : none !important;
}

/*----------------------------------------  Patchwork égal et n/b couleurs */
div.redirectsCards-div >.row > .col {filter: grayscale(1);}div.redirectsCards-div >.row > .col:hover {filter: none;transition: all 0.3s cubic-bezier(.86, .01, .15, .99);}@media all and (min-width: 990px) {div.row-cols-1 >div.col {width : 33.3333333333% !important;}}

/*----------------------------------------  SVG horaires de la bonne couleur*/
#Calque_13 .cls-1 {
  fill: #0475BB; /* Nouvelle couleur */
}

#Calque_6 .cls-1 {
  fill: #0475BB; /* Nouvelle couleur */
}

#Calque_16 .cls-1 {
  fill: #0475BB; /* Nouvelle couleur */
}

#Calque_12 .cls-1 {
  fill: #d4761e; /* Nouvelle couleur */
}

#Calque_17 .cls-1 {
  fill: #d4761e; /* Nouvelle couleur */
}

#Calque_5 .cls-1 {
  fill: #d4761e; /* Nouvelle couleur */
}

#Calque_11 .cls-1 {
  fill: #0475BB; /* Nouvelle couleur */
}

/*----------------------------------------  Bloc galerie d'images égale */
#myGallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colonnes égales */
  gap: 10px; /* Espacement entre les vignettes */
  justify-content: center;
}

#myGallery img {
  width: 25%; /* Chaque vignette prend toute la largeur de sa cellule */
  height: 300px; /* Hauteur fixe pour uniformiser */
  object-fit: cover; /* Recadrage de l'image pour remplir sans déformation */
  border-radius: 8px; /* Optionnel : coins arrondis */
}

/*----------------------------------------  fond couleur bandeau presta */
.card-header pt-4 servicesGallery-card-header text-color-third {
  background-color: #d4761e !important;
}

/*----------------------------------------  couleur éléments bloc maps*/
.d-flex.py-3 {
    color: white; /* Change la couleur de tout le texte et des icônes */
}

.d-flex.py-3 a {
    color: white; /* Change la couleur des liens */
    text-decoration: none; /* Supprime le soulignement */
}


a.nav-link.fs-6.dropdown-toggle.px-0.text-color-secondary.text-center {
    color: #d4761e !important;
    font-weight: bold;
}
